<template>
    <div class="hello" v-if='begin'>
        <div class="head">
            <div class="headLeft">
                <img style="width:80px;max-height:50px" v-if='!domain' src="https://wlzj-platform.posedu.com.cn/manage/uploads/20210818/1bij1mf9tft4c8dkwmhb928ccb9q909g.png">
                <img style="width:500px;max-height:60px" v-else :src="infoList.doorInfo.logo">
                <div v-if='!domain' style='max-height: 50px;margin-top:26px'>
                    <h1 style="margin-bottom: -5px;margin-top: -30px;letter-spacing: 2px;margin-left: 10px;">位来教育大赛展示平台</h1>
                    <p style="margin-bottom: 0;font-size:11px">Wei Lai Education Competition Display Platform</p>
                </div>
                <div v-else style='max-height: 50px;margin-top:26px'>
                    <h1 class='h1class' style="margin-bottom: -5px;margin-top: -30px;letter-spacing: 2px;margin-left: 10px;" v-html='infoList.doorInfo.name'></h1>
                    <!-- <p  style="margin-bottom: 0;font-size:11px">Wei Lai Education Competition Display Platform</p> -->
                </div>
            </div>
            <!-- <div class='iconLine mobileline' style="    border: 1px solid rgb(228 226 226);
      width: 99.5vw;
      margin-left: -18%;"></div> -->
            <div class="headRight" style="position: relative;">
                <!-- <a  @click="goAnchor('#matchnews')">赛事动态</a> -->
                <router-link :to='{path:"/home"}' style='margin-left:20px'>首页</router-link>
                <router-link :to='{path:"/notice",query:{info:domain}}' style='margin-left:30px'>赛事通知</router-link>
                <!-- <router-link  :to='{path:"/matchNew",query:{info:domain}}'  style='margin-left:50px'>报名通道</router-link> -->
                <a @click="goAnchor('#matchnews')" style='margin-left:30px;cursor:pointer;'>报名通道</a>
                <!-- <router-link  :to='{path:"/platform",query:{info:url1}}' target='_blank' style='margin-left:50px'>大赛平台</router-link>
                <router-link   :to='{path:"/aboutus",query:{info:url2}}' target='_blank' style='margin-left:50px'>关于我们</router-link> -->
                <a href='#' style='margin-left:30px' @click="goFun()">省级管理员</a>
                <!-- <i class="inputicon"></i> -->
                <!-- <input style="border-radius: 16px;border-width: 1px; outline: none;height:28px ;width: 189px;    padding-left: 16px;" placeholder="请输入您要搜索内容..."> -->
            </div>
        </div>

        <!-- banner -->
        <div class="banner" v-if="banner.length!=0">
            <div id="slideBox" class="slideBox">
                <swiper :options="swiperOption" ref="mySwiper">
                    <swiper-slide v-for="item in banner" :key="item.id">
                        <a :href='item.url' target='_blank'><img :src="item.banner" style='height: 42.7vh;'></a>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <!-- 箭头 -->
                    <!-- <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div> -->
                </swiper>
            </div>
        </div>

        <!-- <div class="scrollimg">
          <img src="../assets/banner.png" style="width: 100%;
      height: 700px;">
        </div> -->
        <div class="icon" style="display: none;">
            <div class="img">
                <img src="../assets/1.png">
                <div>
                    <p style="font-size: 24px;margin-top: -1px;margin-bottom: 0;">{{infoList.matchCount != null ? infoList.matchCount : 6}}</p>
                    <p style="margin: 0px;">举办场次</p>
                </div>
            </div>
            <div class="img">
                <img src="../assets/2.png">
                <div>
                    <p style="font-size: 24px;margin-top: -1px;margin-bottom: 0;">{{infoList.userCount != null ? infoList.userCount : 115892}}</p>
                    <p style="margin: 0px;">参与人次</p>
                </div>
            </div>
            <div class="img">
                <img src="../assets/3.png">
                <div>
                    <p style="font-size: 24px;margin-top: -1px;margin-bottom: 0;">{{infoList.itemCount != null ? infoList.itemCount : 73524}}</p>
                    <p style="margin: 0px;">作品数量</p>
                </div>
            </div>
            <div class="img">
                <img src="../assets/4.png">
                <div>
                    <p style="font-size: 24px;margin-top: -1px;margin-bottom: 0;">{{infoList.expertCount != null ? infoList.expertCount : 3945}}</p>
                    <p style="margin: 0px;">专家评委</p>
                </div>
            </div>
        </div>
        <div class='iconLine' style="border: 1px solid rgb(228 226 226);width: 99.5vw;margin-left: -18%;"></div>
        <div class="matchnews" id='matchnews'>
            <div class="matchhead">
                <h3>报名通道</h3>
                <!-- <p>more+</p> -->
            </div>
            <template v-if='domain'>
                <div class='contantFather'>
                    <div class="contant" @click="gotoInfo(item.url)" v-for="item in matchDynamic" :key="item.id">
                        <div class="info">
                            <img :src="item.img">
                            <p style='text-align:center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;'>{{item.title}}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="contant" @click="goto(item.domain)" v-for="item in infoList.matchList" :key="item.id">
                <div class="info" >
                  <img :src="item.matchLogo">
                  <p>{{item.matchName}}</p>
                </div>
                </div>  -->
            </template>
            <template v-if='!domain'>
                <div class="contant" @click="goto2">
                    <div class="info">
                        <img src="../assets/match1.jpg">
                        <p style='text-align:center'>全国中医药就创业比赛</p>
                    </div>
                </div>
                <div class="contant" @click="goto2">
                    <div class="info">
                        <img src="../assets/match2.jpg">
                        <p style='text-align:center'>第六届吉人梦想杯就业比赛</p>
                    </div>
                </div>
                <div class="contant" @click="goto3">
                    <div class="info">
                        <img src="../assets/match3.jpg">
                        <p style='text-align:center'>吉林省教师授课大赛</p>
                    </div>
                </div>
                <div class="contant" @click="goto4">
                    <div class="info">
                        <img src="../assets/match4.jpg">
                        <p style='text-align:center'>第七届吉人梦想杯就创业比赛</p>
                    </div>
                </div>
                <div class="contant" @click="goto5">
                    <div class="info">
                        <img src="../assets/match5.png">
                        <p style='text-align:center'>云南北森杯就业比赛</p>
                    </div>
                </div>
                <div class="contant" @click="goto6">
                    <div class="info">
                        <img src="../assets/match6.jpg">
                        <p style='text-align:center'>甘肃就创业征文大赛</p>
                    </div>
                </div>
            </template>
        </div>
        <div class='school' v-if="objnews&&objnews.length>0">
            <h3 style="text-align: left;">资料下载</h3>
            <div class='lexrsda'>
                <a class="file_down" v-for="item in objnews" :key="item.id" @click='down(item.id)'>{{item.fileName}}</a>
            </div>
        </div>
        <div class="school mobileschool" v-if="schoollogoImg&&schoollogoImg.length>0">
            <h3 style="text-align: left;">校级平台</h3>
            <!-- <div class="schoolimg">
              <img>
            </div> -->
            <div style="border: 1px solid #ccc;padding: 15px;margin:25px 0" class="schoolP" v-if='!domain'>
                <img v-for="(item,index) in schoollogoImg" :key="index" :src="item" style="width:100px;height:100px;border:1px solid #fff;border-radius:60px;padding-bottom:8px;margin-left:32px">
            </div>
            <div style="border: 1px solid #ccc;padding: 15px;margin:25px 0" class="schoolP" v-else>
                <img v-for="item in schoollogoImg" :key="item.id" :src="item.logo" style="width:100px;height:100px;border:1px solid #fff;border-radius:60px;padding-bottom:8px;margin-left:32px">
            </div>
        </div>
        <!-- 赛事锦囊 -->
        <div style='margin:auto' class='sadadfc school' v-if="videoList&&videoList.length>0">
            <h3 style="text-align: left;margin-top:50px">赛事锦囊</h3>
            <el-carousel :interval="0" type="card" height="500px" indicator-position="none" @change="vidPP">
                <el-carousel-item v-for="item in videoList" :key="item" @change="vidPP">
                    <video :src='item.url' controls></video>
                    <h2>{{item.name}}</h2>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div style='margin:auto' class='mobile'>
            <el-carousel :interval="0" arrow="always" height="300px" indicator-position="none" @change="vidPP">
                <el-carousel-item v-for="item in videoList" :key="item" @change="vidPP">
                    <video :src='item.url' controls></video>
                    <h2>{{item.name}}</h2>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class='school downliad' v-if="linkList&&linkList.length>0">
            <h3 style='text-align:left'>友情链接</h3>
            <div class='lexrsda'>
                <p v-for='item in linkList' :key='item.id' style='text-align:left'>
                    <a :href='item.url' target='blank'>{{item.name}}</a>
                </p>
            </div>
        </div>

        <div class="footer">
            <div class='mobilefoot' style="margin-left: -147px;margin-top: 24px;">
                <p v-if='domain== "gansu"'>版权所有:甘肃省教育厅</p>
                <p v-if='domain== "gansu"'>技术支持:北京位来教育科技有限公司 CopyRight 2021</p>
                <p v-else>版权所有:北京位来教育科技有限公司 CopyRight 2021</p>
                <p style="text-align: left;">备案号 :京ICP备20011457号-1</p>
                <!-- <p>商务合作：400-4333-222</p>
                <p>公司地址：北京市海淀区</p> -->
            </div>
            <div class='asse' style="    margin-top: 10px;">
                <img src="../assets/二维码.jpg" style="width:80px;height:80px">
                <p style="margin-top: 0;">官方公众号</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {swiper, swiperSlide} from "vue-awesome-swiper";
    import "swiper/dist/css/swiper.css";

    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        },
        components: {
            swiper,
            swiperSlide,
        },
        data() {
            return {
                schoollogoImg: [],
                imgUrl: "http://wlzj2.test.upcdn.net",
                infoList: [],
                banner: [],
                swiperOption: {
                    autoplay: true,
                    circular: true,
                    // notNextTick:true,
                    observer: true,
                    observeParents: true,
                    observeSlideChildren: true,
                    // autoplay: {
                    //   delay: 5000,
                    //   stopOnLastSlide: false,
                    //   disableOnInteraction: false
                    // },
                    // 显示分页
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true //允许分页点击跳转
                    },
                    // 设置点击箭头
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                },
                competingKits: {
                    loop: true,
                    autoplay: {
                        delay: 5000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    // 显示分页
                    pagination: {
                        el: ".swiper-pagination1",
                        clickable: true //允许分页点击跳转
                    },
                    // 设置点击箭头
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    },
                    slidesPerView: 3,//一行显示3个
                    slidesPerColumn: 2,//显示2行
                },
                domain: false,
                url1: '',
                url2: '',
                url3: '',
                videoList: [],
                linkList: [],
                matchDynamic: [],
                objnews: [],
                begin: false
            }
        },
        created() {
            let home2 = window.location.hash
            if (home2.indexOf('2') > 0) {
                this.$router.push('/home2')
            }
        },
        mounted() {
            // this.getDoorInfo()
            this.domain = this.getDomain()
            if (this.domain) {
                this.getDoorInfo()
            } else {
                this.begin = true
                if (this.$route.query.mao) {
                    let anchor = this.$el.querySelector('#matchnews') // 参数为要跳转到的元素id,是(#id)
                    document.body.scrollTop = anchor.offsetTop; // chrome
                    document.documentElement.scrollTop = anchor.offsetTop; // firefox
                }
                this.getSchoolimg()
            }
            // this.$http({
            //   url:'http://192.168.2.25:8080/match/door/list',
            //   method:'post',
            //   data:{
            //     code:1
            //   }
            // }).then(res=>{
            //   this.linkList = res.data
            // })
        },
        methods: {
            getDomain() {
                let domain;
                if(localStorage.getItem("domain")){
                    domain = localStorage.getItem("domain");
                    return domain;
                }
            },
            goFun(){
                if(this.domain=='lgsz'){
                    window.open('https://clg.posedu.com.cn/mms')
                }else{
                    window.open('https://match.posedu.com.cn/mms/#/login')
                }
            },
            getSchoolimg() {
                this.$http({
                    method: 'post',
                    url: 'https://api.match.posedu.com.cn/match/match/getSchoolByDomain',
                    data: {
                        //  domain: ""
                    }
                }).then(res => {
                    let schoolImg = res.data.data
                    schoolImg.forEach(item => {
                        if (item.logo != null && item.logo.search("http") == -1) {
                            let imgurl = this.imgUrl + item.logo
                            this.schoollogoImg.push(imgurl)
                        } else if (item.logo != '') {
                            this.schoollogoImg.push(item.logo)
                        }
                    })
                })
            },
            getDoorInfo() {
                this.$http({
                    url: 'https://api.match.posedu.com.cn/match/match/getDoorInfo',
                    method: 'post',
                    data: {
                        code: this.domain
                    }
                }).then(res => {
                    this.infoList = res.data.data
                    this.schoollogoImg = res.data.data.schoolList
                    this.videoList = JSON.parse(res.data.data.doorInfo.videoUrl)
                    if (this.videoList) {
                        this.videoList.forEach(item => {
                            item.name = item.name.replace('.mp4', '')
                        })
                    }
                    this.linkList = res.data.data.friendlylinks
                    if(res.data.data.doorInfo.bannerList.length!=0)this.banner = res.data.data.doorInfo.bannerList
                    this.url1 = res.data.data.doorInfo.platform
                    this.url2 = res.data.data.doorInfo.aboutUs
                    this.begin = true
                }).then(res => {
                    if (this.$route.query.mao) {
                        let anchor = this.$el.querySelector('#matchnews') // 参数为要跳转到的元素id,是(#id)
                        document.body.scrollTop = anchor.offsetTop; // chrome
                        document.documentElement.scrollTop = anchor.offsetTop; // firefox
                    }
                })
                this.$http({
                    url: `https://api.match.posedu.com.cn/match/dynamic/matchPageDynamicList`,
                    // url: `http://192.168.2.74:8080/match/dynamic/matchPageDynamicList`,
                    method: 'post',
                    data: {
                        page: 1,
                        limit: 100,
                        domain: this.domain,
                        show: 0
                    }
                }).then(res => {
                    this.matchDynamic = res.data.data.list
                })
                this.$http({
                    url: `https://api.match.posedu.com.cn/match/appendix/list`,
                    method: 'post',
                    data: {
                        page: 1,
                        limit: 100,
                        domain: this.domain,
                    }
                }).then(res => {
                    this.objnews = res.data.data.list
                })

            },
            goto(e) {
                window.open(`http://${e}.match.posedu.com.cn`, '_blank');
            },
            //锚点跳转
            goAnchor(selector) {
                this.$router.push({path: "/", query: {mao: 1}})
                var anchor = this.$el.querySelector(selector) // 参数为要跳转到的元素id,是(#id)
                document.body.scrollTop = anchor.offsetTop; // chrome
                document.documentElement.scrollTop = anchor.offsetTop; // firefox
            },
            goto1() {
                window.open('http://pk.wlzjedu.com/', '_blank');
            },
            goto2() {
                window.open('http://jlcareer.match.posedu.cn/', '_blank');
            },
            goto3() {
                window.open('http://jilinjobs.match.posedu.cn/', '_blank');
            },
            goto4() {
                window.open('http://jljcy.match.posedu.cn', '_blank');
            },
            goto5() {
                window.open('http://yunnan.posedu.com.cn', '_blank');
            },
            goto6() {
                window.open('http://gansu.match.posedu.cn/', '_blank');
            },
            gotoInfo(e) {
                window.open(e, '_blank')
                //  this.$router.push({path:'/matchInfo',query:{id:e}})
            },
            vidPP() {
                var videos = document.getElementsByTagName("video"); //获取所有video
                for (var j = videos.length - 1; j >= 0; j--) {
                    videos[j].pause();
                }
            },
            down(id) {
                window.open(`https://api.match.posedu.com.cn/match/appendix/getAppendix/${id}`, '_blank');
            },

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .hello {
        width: 75%;
        margin: 0 auto;
    }

    .head {
        display: flex;
        justify-content: space-between;
    }

    .headRight, .headLeft {
        display: flex;
        align-items: center;
    }

    .scrollimg {
        width: 99.5vw;
        margin-left: -18%;
    }

    .lexrsda {
        display: flex;
        flex-wrap: wrap;
    }

    .lexrsda a {
        width: 50%;
    }

    video {
        height: 81%;
        background-color: black;
        width: 100%;
    }

    /* .banner .slideBox .bd img {
        width: 100%;
        display: block;
    }
    .banner {
        width: 99vw;
        overflow: hidden;
    }
    .banner .slideBox {
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .banner .slideBox .bd {
        position: relative;
        height: 100%;
        z-index: 0;
    }
    .banner .slideBox .bd ul {
        width: 100%;
        margin: 0;
    }
    .banner .slideBox .bd li {
        zoom: 1;
        vertical-align: middle;
    } */
    .icon {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 11px;
    }

    .matchhead {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: -10px;
    }

    .contantFather {
        display: flex;
        flex-wrap: wrap;
        width:100%;
        /*justify-content: space-between;*/
    }

    .matchnews {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
        margin-top: 20px;
    }

    .info {
        border: 1px solid #ccc;
        margin-bottom: 10px;
        height: 254px;
    }

    .footer {
        background-color: #f5f5f5;
        width: 99.5vw;
        margin-left: -18%;
        display: flex;
        justify-content: space-around;
    }

    .headRight > p {
        margin-right: 40px;
    }

    a.file_down:hover {
        cursor: pointer;
    }

    .schoolP {
        max-height: 600px;
        overflow-y: scroll;
        padding: 9px !important;
        display: flex;
        flex-wrap: wrap;
    }

    /* .contantFather .contant{
        width: 21.2%;

    } */
    .contant {
        width: 30.3%;
        margin-bottom: 60px;
        cursor: pointer;
        margin: 35px 0;
        margin-right: 20px;
        /* height: 500px; */
        /* padding: 0 37px; */
    }

    /* .contant:nth-child(4n+1) {
        margin-left: 0 !important;
    }
    .contant:nth-child(4n) {
        margin-right: 0 !important;
    } */
    .contant .info p:hover {
        color: cornflowerblue
    }

    .contant img {
        width: 100%;
        height: 100%;
    }

    .img {
        display: flex;
    }

    .img img {
        /* width: 50px; */
        height: 47px;
        margin-right: 17px;
    }

    .info p {
        /* border: 1px solid #d4d0d0; */
        border-top: 0;
        margin-top: -3px;
        height: 38px;
        padding-top: 11px;
    }

    .head {
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .school {
        margin-top: 50px !important;
    }


    .inputicon {
        width: 24px;
        height: 24px;
        background: url(../assets/搜索.png) no-repeat center center;
        background-size: 100%;
        position: absolute;
        right: 10px;
    }

    .school a {
        color: #0e9ad9;
        padding: 5px 0px;
    }

    .lexrsda p {
        width: 50%;
    }

    div#slideBox {
        width: 99.7vw;
        margin-left: -18%;
    }

    #slideBox img {
        width: 100%;
    }

    .el-carousel.el-carousel--horizontal.el-carousel--card h2 {
        text-align: center;
        background-color: #fff;
        padding-top: 24px;
        margin-top: -16px;
        padding-bottom: 22px;
        margin-bottom: -10px;
        font-size: 22px;
    }

    .el-carousel__arrow, .el-carousel__arrow:hover {
        background-color: green;
        color: blue;
    }

    /deep/ .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
    }

    h1.h1class p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mobile {
        display: none;
    }

    .mobileline {
        display: none;
    }

    @media screen and (max-width: 900px) {
        .contant {
            width: 91vw !important;
            margin: 0 !important;
        }

        .mobileline {
            display: block;
        }

        #slideBox img {
            height: auto !important;
        }

        .hello {
            width: 95%;
        }

        .contantFather {
            margin-top: 15px;

        }

        div#slideBox {
            margin-left: -5% !important;
        }

        .img img {
            height: 19px;
            margin-right: 11px;
            margin-top: 5px;
        }

        .iconLine {
            margin-left: -5% !important;
            border: 0.5px solid rgb(243 243 243);

        }

        .lexrsda a {
            width: 100%;
        }

        .sadadfc.school {
            display: none;
        }

        .mobile {
            display: block;
        }

        .el-carousel__arrow--left {
            left: 0;
        }

        .img div p {
            font-size: 12px;
        }

        .school {
            margin-top: -10px !important;
        }

        /deep/ .el-carousel__arrow {
            top: 40% !important;
        }

        .footer {
            width: 100vw;
            margin-left: -5%;
        }

        .mobilefoot {
            margin: 0 !important;
            margin-left: 8px !important;
        }

        .asse {
            margin-right: 8px !important;
        }

        .headLeft img {
            width: 300px !important;
        }

        .head {
            display: flex;
            flex-wrap: wrap;
        }

        .headRight {
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 10px;

        }

        h2 {
            font-size: 18px;
            text-align: center;
            font-weight: 500;
        }

        .headLeft {
            margin-bottom: 15px;
            padding-left: 10px;
        }

        .headRight a {
            margin-left: 0 !important;
            padding: 5px;
        }

        .schoolP {
            /* margin-left: 0.5rem !important; */
        }

        .schoolP img {
            width: 19% !important;
            height: 19% !important;
            margin-left: 0 !important;
            padding-left: 1px;
        }

        .contant img {
            height: auto !important;
        }

        .info {
            height: auto !important;
            border: none;
        }

        .mobileschool {
            margin-top: 25px !important;
        }

        .mobilefoot p {
            font-size: 14px;
        }

        .asse p {
            font-size: 15px;
        }
    }
</style>
