<template>
  <div id="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "App",
  // components: {
  //   HelloWorld
  // }
  // url: "http://192.168.2.74:8080/match/match/getDoorInfo"
  // url: "https://api.match.posedu.com.cn/match/match/getDoorInfo"
  created() {
    this.domain = this.getDomain();
    if (this.domain) {
      this.$http({
        url: "https://api.match.posedu.com.cn/match/match/getDoorInfo",
        method: "post",
        data: {
          code: this.domain,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          //      if(res.data.data.doorInfo.name){
          //        let text = res.data.data.doorInfo.name
          // let  replace = text.replace(/<[^>]+>/g,"");
          //  document.title = replace

          //    }

          let regex = /(<([^>]+)>)/gi;
          // document.title = res.data.data.doorInfo.name.replace(/<[^>]+>/g, "")
          if (
            res.data.data.doorInfo.name &&
            res.data.data.doorInfo.name.trim() != ""
          ) {
            document.title = res.data.data.doorInfo.name.replace(regex, "");
          } else if (
            res.data.data.doorInfo.platform &&
            res.data.data.doorInfo.platform.trim() != ""
          ) {
            console.log(2);
            document.title = res.data.data.doorInfo.platform.replace(regex, "");
          }
          console.log(document.title);
          if (res.data.data.doorInfo.logo) {
            var link =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = res.data.data.doorInfo.logo; //icon图标
            document.getElementsByTagName("head")[0].appendChild(link);
          }
        } else {
          this.$router.push("/nomatch");
        }
      });
    } else {
      document.title = "位来教育大赛展示平台";
    }
  },
  methods: {
    getDomain() {
      // let domain = 'chongqing';
      let domain = window.location.hostname.split(".");
      if (domain.length == 5) {
        domain = domain[0];
      } else {
        domain = "";
      }
      localStorage.setItem("domain", domain);
      return domain;
    },
  },
};
</script>

<style>
#app {
  font-family: Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

.router-link-active {
  color: #00baff !important;
  border-bottom: 6px solid #00baff;
  border-width: 3px;
}
</style>
